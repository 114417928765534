* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

[data-reactroot] {
  height: 100% !important; 
}

div#root {
  height: 100%;
}

html {
  height: 100%;
}

body {
  font-family: sans-serif;
  height: 100%;
}

header {
  height: 58px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  color: #212529;
}

.full-height {
  height: 100%;
}

.full-min-height {
  min-height: 100%;
}

.nav-area {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  height: 58px;
}

.logo {
  text-decoration: none;
  font-size: 25px;
  color: inherit;
  margin-right: 20px;
}

.left-sidebar {
  background-color: #0d6efd;
  min-height: 100%;
  padding: 0;
  color: white;
  padding-bottom: 100vh;
}

.menus {
  padding: 0;
  list-style: none;
}

.menu-items {
  position: relative;
  font-size: 14px;
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.menu-items button {
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

.menu-items a,
.menu-items button {
  text-align: left;
  padding: 0.7rem 1rem;
}

.menu-items a:hover,
.menu-items button:hover {
  background-color: #5294f7;
}

.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.dropdown-ml {
  position: absolute;
  right: 0;
  left: 100%;
  top: 0;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 10rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #0041a3;
  border-radius: 0.5rem;
  display: none;
}

.dropdown-ml.show {
  display: block;
}

.dropdown-ml .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}
  
.bg-grey {
  background-color: #ccc;
}

.files {
  list-style-type: none;
  padding: 0;
}

.files li.file-entry {
  padding: 25px;
  border-radius: 25px;
  margin-bottom: 25px;
  border: 2px solid #555;
  white-space: pre-wrap;
}

.image-files {
  list-style-type: none;
  padding: 0;
}

.image-files li.file-entry {
  padding: 25px;
  border-radius: 25px;
  margin-bottom: 25px;
  border: 2px solid #555;
}

.image-files li img {
  max-width: 100%;
}

.file-content {
  white-space: pre-wrap;
}

.file-content ul {
  list-style-type: disc;
}

.file-content ul li {
  margin-bottom: 0;
  padding: 0;
  border: none;
}

.file-content ol li {
  margin-bottom: 0;
  padding: 0;
  border: none;
}

.brand {
  padding-top: 15px;
  padding-left: 15px;
}

.edit-btn {
  margin: 5px;
}

.delete-btn {
  margin: 5px;
}

.append-btn {
  margin: 5px;
}

.pin-btn {
  margin: 5px;
}

.download-btn {
  margin: 5px;
}

.btn-delete-category {
  margin: 5px;
}

.btn-set-sort-index {
  margin: 5px;
}

.swal2-confirm {
  background-color: #0B5ED7 !important;
}

.logout-btn {
  padding: 10px;
  cursor: pointer;
  margin-left: 5px;
}

.menu-buttons {
  text-align: left;
}

/* Pagination */
.navigationButtons {
	width: 80%;
	height: 40px;
	list-style: none;
	display: flex;
	justify-content: center;
	margin: auto;
	padding-top: 40px;
	margin-bottom: 80px;
}
	
.navigationButtons a {
	padding: 10px;
	margin: 8px;
	border-radius: 5px;
	border: 1px solid black;
	color: black;
	cursor: pointer;
}
	
.navigationButtons a:hover {
	color: white;
	background-color: #444;
}
	
.navigationActive a {
	color: white;
	background-color: #444;
}
	
.navigationDisabled a {
	color: white;
	background-color: grey;
}

/* Bookmarks */
.add-bookmark-folder {
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #ccc;
  border-radius: 10px;
  padding: 25px;
}

.add-bookmark {
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #ccc;
  border-radius: 10px;
  padding: 25px;
}

.remove-bookmark-dups {
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #ccc;
  border-radius: 10px;
  padding: 25px;
}

.btn-upload-bookmarks {
  margin-top: 5px;
  margin-bottom: 5px;
}

.upload-bookmarks {
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #ccc;
  border-radius: 10px;
  padding: 25px;
}

.btn-add-bookmark-folder {
  margin-top: 5px;
  margin-bottom: 5px;
}

.btn-add-bookmark {
  margin-top: 5px;
  margin-bottom: 5px;
}

.btn-remove-bookmark-dups {
  margin-top: 5px;
  margin-bottom: 5px;
}

.btn-delete-all-bookmarks {
  margin-top: 5px;
  margin-bottom: 5px;
}

.btn-edit-bookmark {
  margin: 5px;
}

.btn-delete-bookmark {
  margin: 5px;
}

/* Login */

.login-box {
  padding: 25px;
  background-color: #ccc;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-check-input {
  width: 40px;
  height: 40px;
}

.bg-grey {
  background-color: #ccc;
  border: none;
}

.upload-bookmarks-control {
  margin-top: 5px;
  margin-bottom: 5px;
}

.rounded {
  border-radius: 10px;
}

.customReactSelectMenu {
  z-index: 9999;
  background-color: white;
}
